// 主題顏色
// 初始為空對象。當應用初始化時,會從 CSS 變量中賦值給這個對象
export const $themeColors = {}

// 應用斷點
// 初始為空對象。當應用初始化時,會從 CSS 變量中賦值給這個對象
export const $themeBreakpoints = {}

// 應用配置
export const $themeConfig = {
  app: {
    appName: '控 端', // 將更新導航菜單中的名稱 (品牌)
    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/super.png'), // 將更新導航菜單中的 logo (品牌)
    version: '20240113',
  },
  layout: {
    isRTL: false, // 是否使用從右到左的佈局
    skin: 'light', // 皮膚主題: light(亮色), dark(暗色), bordered(帶邊框), semi-dark(半暗色)
    routerTransition: 'zoom-fade', // 路由轉場動畫: zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // 佈局類型: vertical(垂直), horizontal(水平)
    contentWidth: 'full', // 內容寬度: full(全寬), boxed(盒裝)
    menu: {
      hidden: false, // 菜單是否隱藏
      isCollapsed: (!localStorage.getItem('isCollapsed') || localStorage.getItem('isCollapsed') == '1'), // 菜單是否折疊
    },
    navbar: {
      // 對於水平菜單,navbar 類型將應用於 navMenu
      type: 'floating', // navbar 類型: static(靜態), sticky(粘性), floating(浮動), hidden(隱藏)
      backgroundColor: '', // navbar 背景顏色 (BS 顏色選項 [primary, success 等])
    },
    footer: {
      type: 'hidden', // footer 類型: static(靜態), sticky(粘性), hidden(隱藏)
    },
    customizer: false, // 是否啟用主題定制器
    enableScrollToTop: true, // 是否啟用滾動到頂部功能
  },
}
